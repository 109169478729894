.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
}

.color-orange {
  color: #ffc701;
}
.bg-orange {
  background-color: #ffc701;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

  .mainclass{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
}
@media screen and (max-width: 800px){
  .mainclass{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
}
@media screen and (max-width: 600px){
  .mainclass{
display: flex;
flex-direction: column;
gap: 100px;
align-items: center;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  body {
    font-size: 10px;
  }
}

.page-margin{
  padding: 20px 25px;
  height: 100vh;
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  .page-margin{
    padding: 5px 2px;
    .card {
      padding: 2px;
    }
  }
}

