html,
body {
  width: 100%;
  height: 100%;
  background-image: none;
}
.wrapper * {
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: row;
}
.tabActive {
  border-radius: 5px;
  /* padding: 3%; */
  background-color: rgba(255, 199, 1, 0.2);
}
.padding {
  cursor: pointer;
  /* border: 2px solid blue; */
}
.sidebar {
  width: 12%;
  /* border: 2px solid yellow; */
  height: 100%;
  background-image: url(../../assets/img/dashboard_img/sidebar.jpeg);
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.dashboard {
  width: 88%;
  height: 100%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
}
.sidebarMain {
  /* border: 2px solid red; */
  width: 72%;
  height: 98%;
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  /* gap: 5%; */
  justify-content: space-around;
}
.logo {
  display: flex;
  justify-content: center;
}
.createBtn {
  width: 100%;
  height: 6vh;
  /* border: 2px solid blue; */
}
.createBtn img {
  width: 20%;
  height: 50%;
}
.createBtn button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: 600;
  gap: 5%;
  border: none;

  border-radius: 5px;
  background-color: #ffc701;
}

/* .community > div:nth-child(2) {
  color: #ffc701;
} */
.community > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10%;
  color: white;
  margin-bottom: 2%;
  padding-left: 4%;
  /* border: 2px solid red; */
  min-height: 5vh !important;
  -webkit-user-select: none;
  user-select: none;
}
.community {
  width: 100%;
  height: fit-content;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  /* gap: 30%; */
  gap: 15px;
}
.section1 {
  width: 100%;
  height: 70%;
  /* border: 2px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.section2 {
  /* border: 2px solid pink; */
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-end;
  /* justify-content: space-around; */
  padding-bottom: 10%;
}
.section2Main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section2Main > div:first-child {
  /* border: 2px solid red; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.section2Main > div:first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section2Main {
  width: 100%;
  height: 25%;
  /* border: 2px solid pink; */
  display: flex;
  justify-content: flex-start;
  color: white;
  gap: 8%;
  /* gap: 10%; */
  align-items: center;
  div:nth-child(2) {
    margin-top: 5%;
    /* border: 2px solid red; */
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1vmax;

    text-align: center;
    position: relative;
  }
}
.section2Main h5 {
  font-weight: 600;
  display: inline;
}
.section2Main h6 {
  font-weight: 400;
  color: #c0c0c0;
  display: inline;
}
.version {
  color: rgba(255, 255, 255, 0.473);
  position: absolute;
  bottom: 0%;
  font-size: 0.6vmax;
  /* border: 2px solid red; */
  /* width: 100%; */
}
.produce-section {
  width: 100%;
  height: 22%;
  border-bottom: 2px solid #e0e0e0;
  /* border: 2px solid green; */

  display: flex;
  align-items: center;
  justify-content: center;
}
.produce-section-main2 {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3.33%;
}
.produce-section-main2 > div:first-child > div:first-child {
  /* border: 2px solid red; */
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3%;
}
.produce-section-main2 > div:first-child {
  border-bottom: 2px solid rgba(128, 128, 128, 0.438);
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.produce-section-main2 > div {
  width: 100%;
  height: 30%;

  display: flex;
  align-items: center;
}
.produce-section-tabs {
  width: 70% !important;
  /* border: 2px solid green; */
}
.produce-section-tabs > div {
  width: 25%;
  /* border: 2px solid blue; */
  height: 100%;
  border-bottom: 2px solid rgba(128, 128, 128, 0.438);
}
.produce-section-tabs > div > div {
  display: flex;
  align-items: flex-end;
  gap: 2%;
  font-size: 1.5vmax;
  width: 43%;
  height: 100%;

  /* border-bottom: 2px solid rgba(128, 128, 128, 0.438); */

  /* border: 2px solid pink; */
}
.produce-section-tabs > div > div img {
  width: 22%;
}
.produce-section-main p {
  color: #828282;
}
.produce-section-main {
  /* border: 2px solid red; */
  width: 95%;
  height: 90%;
}
.produce-section-main button {
  width: 15%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: 600;
  gap: 5%;
  border: none;

  border-radius: 5px;
  background-color: #ffc701;
}
.produce-section-main > div {
  display: flex;
  align-items: center;
  gap: 1%;
  width: 100%;
  height: 30%;
  /* border: 2px solid pink; */
}
.produce-section-main > div:first-child img {
  height: 50%;
}
.choira-test-project-section {
  /* border: 2px solid blue; */
  height: 77%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choira-test-project-section-main {
  /* border: 2px solid green; */
  width: 90%;
  height: 98%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choira-test-project-section-main span {
  color: #828282;
}
.choira-test-project-section-main > div > div {
  line-height: 25px;
}

.choira-test-project-section-main > div {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  gap: 2%;

  width: 90%;
  height: 95%;
  overflow-y: auto;
}

.choira-test-details {
  width: 90%;
  height: 40%;
  /* border: 2px solid red; */
}
.choira-test-details > h4 {
  overflow-y: auto; /* This enables a vertical scrollbar if content overflows vertically */
  overflow-x: hidden;
  white-space: pre-wrap;

  height: 50px;
  /* border: 2px solid red; */
  margin-top: 1%;
  margin-bottom: 1%;
  word-wrap: break-word;
  width: fit-content;
  max-width: 100%;
}

.choira-test-details > div > div:first-child {
  background-color: #fff4cc;
  color: #ffc701;
  border-radius: 5px;
  width: auto;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.8vmax;
  padding-left: 5px;
  padding-right: 5px;
}
.choira-test-details > div > div:nth-child(2) {
  background-color: #ffdad8;
  color: #ff473b;
  border-radius: 5px;
  width: auto;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 0.8vmax;
  padding-left: 5px;
  padding-right: 5px;
}
.choira-test-details > div {
  display: flex;
  gap: 5%;
  /* border: 2px solid red; */
  height: 30%;
  width: 100%;
}
.choira-test-demoFile {
  width: 80%;
  height: 30%;
  /* border: 2px solid red; */
}
.choira-test-demoFile-main {
  height: 75% !important;
  width: 90% !important;
  /* border: 2px solid blue; */
  gap: 3.33%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.choira-test-demoFile-main > div {
  width: 15%;
  height: 100%;
  border: 2px solid #828282;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.choira-test-demoFile-main > div > img {
  height: 50%;
  width: 60%;
}
.project-section {
  /* border: 2px solid red; */
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-main {
  width: 95.5%;
  height: 85%;
  /* border: 2px solid pink; */
  display: flex;
  justify-content: center;

  gap: 8%;
  flex-direction: row;
}
.project-main > div {
  width: 20%;
  border-radius: 10px;
  background: #ebecf0;

  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}
.project-main > div > div:first-child {
  /* border: 2px solid blue; */
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  font-size: 1.5vmax;
}
.project-main > div > div img {
  height: 40%;
}
.project-main-content {
  /* border: 2px solid green; */
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dasboard_project_tab_card {
  width: 100%;
  height: 30%;
  border: 10px solid transparent;
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2%;
  flex-direction: column;
}

.dasboard_project_tab_card > div:nth-child(1) {
  width: fit-content;
  height: 20%;
  /* border: 2px solid green; */
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 30px;
  font-weight: 600;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.dasboard_project_tab_card > div:nth-child(2) {
  font-size: 1vmax;
  font-weight: 700;
  width: 100%;
  height: 30%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
}
.dasboard_project_tab_card > div:nth-child(3) {
  width: 100%;
  height: 30%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
}
.dasboard_project_tab_card > div:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30%;
  border-top: 2px solid rgba(128, 128, 128, 0.329);
}

/* dasboard_progress_tab_card  */

.dasboard_progress_tab_card {
  width: 100%;
  height: 25%;
  border: 10px solid transparent;
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2%;
  flex-direction: column;
}

.dasboard_progress_tab_card > div:nth-child(1) {
  width: fit-content;
  height: 25%;
  /* border: 2px solid green; */
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 30px;
  font-weight: 600;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.dasboard_progress_tab_card > div:nth-child(2) {
  font-size: 1vmax;
  font-weight: 700;
  width: 100%;
  height: 40%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
}
.dasboard_progress_tab_card > div:nth-child(3) {
  width: 100%;
  height: 30%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid rgba(128, 128, 128, 0.329);
}

/* payment card  */
.dasboard_payment_tab_card {
  width: 100%;
  height: 45%;
  border: 10px solid transparent;
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2%;
  flex-direction: column;
}

.dasboard_payment_tab_card > div:nth-child(1) {
  width: fit-content;
  height: 50px;
  /* border: 2px solid green; */
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 30px;
  font-weight: 600;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.dasboard_payment_tab_card > div:nth-child(2) {
  font-size: 1vmax;
  font-weight: 700;
  width: 100%;
  height: 30%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
}
.dasboard_payment_tab_card > div:nth-child(3) {
  width: 100%;
  height: 10%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
}
.dasboard_payment_tab_card > div:nth-child(4) {
  display: flex;
  align-items: center;

  width: 100%;
  height: 30%;
  /* border-top: 2px solid rgba(128, 128, 128, 0.329); */
}
.dasboard_payment_tab_card > div:nth-child(6) {
  display: flex;
  align-items: center;

  width: fit-content;
  height: 30%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 30px;
  background-color: #ffc701;
}
.dasboard_payment_tab_card > div:nth-child(7) {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 20%;
  border-top: 2px solid rgba(128, 128, 128, 0.329);
}

/* message card section  */

.dasboard_message_tab_card {
  width: 100%;
  height: 35%;
  border: 10px solid transparent;
  margin-bottom: 5%;
  border-radius: 10px;
  background-color: white;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 2%;
  flex-direction: column;
}

.dasboard_message_tab_card > div:nth-child(1) {
  font-size: 1vmax;
  font-weight: 700;
  width: 100%;
  height: 40%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
}
.dasboard_message_tab_card > div:nth-child(2) {
  width: 100%;
  height: 30%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
}
.dasboard_message_tab_card > div:nth-child(3) {
  width: 100%;
  height: 40%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid rgba(128, 128, 128, 0.329);
}

.dasboard_project_tab {
  width: 90%;
  height: 100%;
  overflow-y: auto;

  /* border: 2px solid green; */
}
.hrline {
  width: 100%;
  border: 2px solid yellow;
  height: 0%;
}

.choira-test-btn > div:first-child {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.choira-test-btn > div:first-child > button {
  width: 10%;
  height: 70%;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #ffc701;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choira-test-btn > div:first-child > button:hover {
  background-color: #ffb301;
}
.choira-test-btn > div:nth-child(2) {
  width: 20%;
  /* border: 2px solid red; */
  display: flex;
  height: 90%;
  align-items: center;
  justify-content: space-between;
}
.choira-test-btn > div:nth-child(2) > div {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;

  /* border: 2px solid blue; */
}
.choira-test-btn > div:nth-child(2) > div button {
  height: 80%;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffc701;
  color: black;
  gap: 8%;
  font-size: 1vmax;
  border: none;
  outline: none;
  cursor: pointer;
}
.choira-test-btn > div:nth-child(2) > div button:hover {
  background-color: #ffb301;
}

.choira-test-project-section-main-2 {
  /* border: 2px solid green; */
  width: 90%;
  height: 98%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
}
.progress-div {
  width: 85%;
  height: 90%;
  /* border: 2px solid red; */
}
.progress_main_div {
  width: 100%;
  height: 20%;
  /* border: 2px solid yellow; */
}
.progress_main_div > div:first-child > div:nth-child(2) {
  /* border: 2px solid pink; */
  width: 24%;
  background-image: url(../../assets/img/look.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 1%;
}
.progress_main_div > div:first-child {
  width: 100%;
  height: 40%;
  /* border: 2px solid green; */
  display: flex;
  justify-content: space-between;
  font-size: 1.2vmax;
}
.progress_main_div > div:first-child > div {
  font-size: 1.2vmax;
  font-weight: 600;
  color: #565656;
}
.progress_main_div > div:nth-child(2) {
  width: 100%;
  height: 60%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
}
.progress_main_div > div:nth-child(2) > div:nth-child(1) {
  width: 18%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1vmax;
  font-weight: 600;
  color: #ffc701;
  background-color: #fff4cc;
  border-radius: 15px;
}
.progress_main_div_timer {
  width: 25%;
  /* padding-left: 1%;
  padding-right: 1%; */
  height: 100%;
  border: 2px solid gray;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  margin-top: -2px;
}
.progress_main_div_content {
  width: 100%;
  height: 80%;
  /* border: 2px solid green; */
}
.progress_main_div_content > div {
  width: 100%;
  height: 23%;
  /* border: 2px solid orange; */
}
.progress_main_div_content > div:nth-child(3) {
  height: 31%;
}
.progress_main_div_content > div > div > div > div:first-child {
  color: rgb(224, 224, 224);
}

.progress_main_div_content > div > div {
  width: 100%;
  height: 100%;

  /* border: 2px solid black; */
}
.progress_main_div_content > div > div > div:first-child {
  display: flex;
  width: 100%;
  height: 30%;
  /* border: 2px solid green; */
  gap: 1%;
  align-items: center;
  font-size: 1.5vmax;
  color: rgb(224, 224, 224);
}
.progress_main_div_content > div > div > div:nth-child(2) {
  width: 100%;
  height: 68%;
  border-left: 5px solid rgb(224, 224, 224);
  color: rgb(224, 224, 224);
  /* border: 2px solid blue; */
  padding-left: 3%;
  margin-left: 0.8%;
}
.progress_main_div_content button {
  width: 20%;
  height: 50%;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1.2vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  background-color: #ffc701;
  margin-top: 0.5%;
}
.test-overlay {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  background-color: #09090981;
  top: 0%;

  z-index: -100;
}
.test-overlay-after {
  opacity: 1;
  z-index: 100;
}
.choira_edit_profile {
  left: 50%;
  top: -100%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 25%;
  height: 80%;
  /* border: 2px solid red; */
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background-color: #262727;
  transition: 1s ease;
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.choira_edit_profile > div {
  width: 90%;
  height: 100%;
}
.choira_edit_profile > div > div {
  width: 100%;
  height: 16%;
  /* border: 2px solid green; */
  display: flex;
  justify-content: space-between;
}
.choira_edit_profile > div > div > div {
  width: 50%;
  height: 100%;
  /* border: 2px solid red; */
}

.choira_edit_profile-after {
  top: 50%;

  transition: 1s ease;
}
.choira_edit_profile > div > div:nth-child(1) {
  width: 100%;
  height: 10%;
  /* border: 2px solid blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.choira_edit_profile > div > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}
.choira_edit_profile > div > div:nth-child(1) > div:nth-child(2) {
  justify-content: flex-end;
}
.choira_edit_profile > div > div:nth-child(2) {
  height: 25%;
  justify-content: center;
  align-items: center;
}
.choira_edit_profile > div > div:nth-child(2) img {
  width: 100%;
  height: 100%;
}
.choira_edit_profile > div > div:nth-child(2) > div:first-child {
  height: 120px;
  width: 120px;
  /* border: 2px solid red; */
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.choira_edit_profile > div > div:nth-child(2) > div:nth-child(2) {
  /* border: 2px solid red; */
  /* border: 2px solid red; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 0.6vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40%;
  top: 27%;
  background-color: #ffc701;
  color: black;
}
.choira_edit_profile input,
.choira_edit_profile input select {
  background-color: transparent;
  border: 2px solid gray;
  width: 80%;
  height: 50%;
  margin-top: 10px;
  border-radius: 5px;
  color: white;
  padding-left: 5px;
}
.choira_edit_profile > div > div:nth-child(6) {
  align-items: center;
  justify-content: center;
}
.choira_edit_profile > div > div:nth-child(6) button {
  width: 30%;
  height: 40%;
  background-color: #ffc701;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}
.choira_edit_profile > div > div:nth-child(6) button:hover {
  background-color: #ffb301;
}
.choira_edit_profile select option {
  color: white;
  background-color: #262727;
}
.choira_test_number > input {
  position: relative;
}
.choira_test_number > div {
  position: absolute;
  margin-top: -8%;
  padding-left: 8px;
  font-size: 0.7vmax;
}
.choira_test_number > input {
  padding-left: 20%;
}

.choira_test_payment {
  /* border: 2px solid green; */
  width: 90%;
  height: 98%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ctp_main {
  width: 85%;
  height: 90%;
  /* border: 2px solid red; */
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5%;
}
.ctp_main > div:first-child {
  width: 98%;
  height: 50%;
  /* border: 2px solid blue; */
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.ctp_main > div:nth-child(2) {
  height: 60%;
  /* border: 2px solid green; */

  flex-direction: column;
}
.ctp_main > div:first-child {
  width: 98%;
  height: 30%;
  box-shadow: none;
  /* border: 2px solid red; */
  gap: 2%;
}
.ctp_main > div:first-child > div {
  width: 49%;
  height: 98%;
  /* border: 2px solid green; */
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.price_section > div {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
}
.ctp_main h3 {
  color: black;
}
.price_section > div > div:first-child {
  width: 70%;
  height: 100%;
  /* border: 2px solid yellow; */
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  justify-content: center;
}
.price_section > div > div:first-child > div:first-child {
  font-size: 1vmax;
  font-weight: 700;
  color: #606060;
}
.price_section > div > div:first-child > div:last-child {
  font-size: 2vmax;
  font-weight: 700;
  color: black;
}
.price_section > div > div:last-child {
  width: 30%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 10%;
  justify-content: center;
}
.price_section > div > div:last-child > div {
  width: 100%;
  height: 40%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_section > div > div:last-child > div:first-child > div:first-child {
  font-size: 1vmax;
  font-weight: 500;
  color: green;
}
.price_section > div > div:last-child > div > div:last-child {
  font-size: 1.5vmax;
  font-weight: 500;
  color: back;
}
.price_section > div > div:last-child > div:last-child > div:first-child {
  color: red;
  font-size: 1vmax;
  font-weight: 500;
}
.payment_mode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5%;
}
.payment_mode > div {
  width: 80%;
  height: 40%;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
}
.payment_mode > div:first-child {
  display: flex;
  align-items: center;
}
.payment_mode > div:first-child > div:first-child {
  width: 7%;
  height: 50%;
  border: 2px solid gray;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
.payment_mode > div:first-child > div:first-child img {
  height: 30px;
}
.payment_mode > div:first-child > div:nth-child(2) {
  width: 60%;
  /* border: 2px solid green; */
  margin-left: 5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.payment_mode > div:first-child > div:nth-child(3) {
  /* border: 2px solid blue; */
  padding-left: 15%;
}

.payment_mode > div:last-child {
  display: flex;
  align-items: center;
}
.payment_mode > div:last-child > div:first-child {
  width: 7%;
  height: 50%;
  border: 2px solid gray;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
}
.payment_mode > div:last-child > div:first-child img {
  height: 30px;
}
.payment_mode > div:last-child > div:nth-child(2) {
  width: 60%;
  /* border: 2px solid green; */
  margin-left: 5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.payment_mode > div:last-child > div:nth-child(3) {
  /* border: 2px solid blue; */
  padding-left: 15%;
}

.choira_test_pay_status {
  display: flex;
  align-items: center;
  /* border: 2px solid pink; */
  width: 100%;
  height: 28%;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 1%;
  border-radius: 10px;
}
.choira_test_pay_status p {
  color: black;
}
.payment_mode p {
  color: black;
}
.choira_test_pay_status > div:first-child {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choira_test_pay_status > div:first-child > div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 3px solid #ffc701;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7vmax;
}
.choira_test_pay_status > div:nth-child(2) {
  width: 60%;
  height: 80%;
  display: flex;

  justify-content: center;
  flex-direction: column;
  gap: 5%;
}
.choira_test_pay_status > div:nth-child(3) {
  width: 10%;
  font-size: 1vmax;
  font-weight: 600;
  margin-left: 10%;
}
.choira_test_pay_status > div:nth-child(4) {
  width: 10%;
  height: 40%;
  background-color: var(--Neutral-Neutral-400, #9ca3af);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vmax;
  gap: 10%;
  border-radius: 10px;
  color: white;
}

.message_section {
  width: 90%;
  height: 98%;
  /* border: 2px solid red; */
}
.message_section > div:nth-child(1) {
  width: 100%;
  height: 15%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  gap: 1%;
  /* justify-content: center; */
}
.message_section > div:nth-child(1) > div:first-child {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 2px solid green; */
  overflow: hidden;
}
.message_section > div:nth-child(1) > div:first-child > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.message_section > div:nth-child(1) > div:last-child {
  width: 40%;
  height: 100%;
  display: flex;
  gap: 5%;

  justify-content: center;
  flex-direction: column;
}
.message_section > div:nth-child(1) > div:last-child h6 {
  font-weight: 400;
  font-size: 1vmax;
}
.message_section > div:nth-child(2) {
  width: 100%;
  height: 70%;
  /* border: 2px solid black; */
}
.message_section > div:nth-child(3) {
  width: 100%;
  height: 10%;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  margin-top: 1%;
  display: flex;
  align-items: center;
}
.message_section > div:nth-child(3) > textarea {
  width: 85%;
  height: 50%;
  padding-left: 10%;
  margin-left: 2%;

  /* border: 2px solid blue; */

  border: none;
  outline: none;

  /* justify-content: center; */
}

.message_section > div:nth-child(3) > div {
  right: 0%;
  /* border: 2px solid green; */
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  margin-right: 2%;
  cursor: pointer;
}

/* .chat-container {
  max-width: 400px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
} */

.chat-messages {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.chat-messages div {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  background-color: #e0e0e0;
  word-wrap: break-word;
  width: fit-content;
  max-width: 50%;
  align-self: flex-start;
}

/* Style for messages sent by the user */
.chat-messages div.sent-message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  background-color: #fff4cc;
  color: black;
  text-align: right;
  word-wrap: break-word;
  width: fit-content;
  max-width: 50%;
  align-self: flex-end;
}

/* Style for messages received from the second user */
.chat-messages div.received-message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;

  word-wrap: break-word;
  width: fit-content;
  max-width: 50%;
  align-self: flex-start;
  align-items: flex-start;
  background-color: #fff4cc;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #eee;
}

.chat-input input {
  flex: 1;
  padding: 8px;
}

/* button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: black;
  border: none;
  cursor: pointer;
} */
.mobview {
  display: none;
}
.choira-test-player {
  width: 450px;
  height: 250px;
}
.mob_progress_main_div {
  display: none;
}
/* .paymentStatus {
  border: 2px solid blue;
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: scroll;
} */
@media only screen and (min-width: 1024px) and (max-width: 1550px) {
  .dasboard_project_tab_card {
    height: 40%;
  }
}
@media only screen and (max-width: 768px) {
  /* .mobDisplayNone {
    display: none;
  } */
  .mobview {
    display: block;
  }
  .dashboard_after {
    filter: blur(2px);
  }
  .sidebar {
    position: fixed;
    right: -100%;
    width: 50%;
    transition: 0.7s ease;
    z-index: 100;
    box-shadow: rgb(0, 0, 0) 0px 5px 45px;
    /* filter: blur(0px) !important; */
  }
  .sidebar_after {
    right: 0%;
    transition: 0.7s ease;
    /* box-shadow: 10px 5px 5px red; */
    /* border: 2px solid #ffc701; */
    /* border: 2px solid white; */

    /* filter: blur(0px) !important; */
  }
  .section1 > div:first-child {
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3vmax;
    color: white;
    width: 100%;
  }
  .section1 > div:first-child img {
    width: 60%;
  }
  .dashboard {
    /* border: 2px solid red; */
    width: 100%;
    background-color: #1e1e1e;
    color: white;
    /* -webkit-filter: blur(5px); */
  }

  .produce-section-tabs {
    width: 90% !important;
  }
  .produce-section-tabs > div > div {
    width: 85%;
    font-size: 2.1vmax;
  }
  .mobview {
    font-size: 2.5vmax;
  }
  .choira-test-details > div > div:first-child {
    background-color: #1e1e1e;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .choira-test-details > div > div:nth-child(2) {
    background-color: #1e1e1e;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .choira-test-demoFile-main > div {
    width: 40%;
    height: 80%;
  }
  .choira-test-player {
    width: 250px;
    height: 150px;
  }
  .choira-test-btn > div:nth-child(2) {
    width: 40%;
  }
  /* .choira-test-btn > div:nth-child(2) > div {
    width: 65%;
    gap: 5%;
  } */
  .choira-test-btn > div:nth-child(2) > div button {
    width: 100%;
    height: 62%;
    font-size: 1.4vmax;
  }
  .choira-test-btn > div:first-child > button {
    width: 30%;
    height: 55%;
  }
  .progress_main_div {
    display: none;
  }
  .mob_progress_main_div {
    display: block;
    width: 100%;
    height: 40%;
    /* border: 2px solid yellow; */
  }
  .mob_progress_main_div > div:first-child {
    /* border: 2px solid green; */
    width: 100%;
    height: 60%;
  }
  .mob_progress_main_div > div:first-child > div:first-child {
    /* border: 2px solid pink; */
    width: 98%;
    height: 40%;
    background-image: url(../../assets/img/look.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    font-size: 2vmax;
  }
  .progress_main_div_timer {
    width: 100%;
    height: 50%;
    /* margin-top: -1px; */
  }
  .mob_progress_main_div > div:nth-child(2) {
    /* border: 2px solid red; */
    width: 100%;
    height: 40%;
  }
  .mob_progress_main_div > div:nth-child(2) > div:first-child {
    width: 100%;
    height: 40%;
    /* border: 2px solid rebeccapurple; */
    font-size: 2vmax;
  }
  .mob_progress_main_div > div:nth-child(2) > div:nth-child(2) {
    width: fit-content;
    height: fit-content;
    /* border: 2px solid green; */
    padding: 3%;
    font-size: 2vmax;
    border-radius: 10px;
    background-color: #4b4018;
    color: #ffc701;
    font-weight: 600;
  }
  .progress_main_div_content button {
    width: 60%;
    height: 30%;
    font-size: 1.8vmax;
  }
  .ctp_main > div:first-child {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ctp_main > div:first-child > div {
    width: 100%;
  }
  .price_section > div > div:first-child > div:first-child {
    color: #ffc701;
    font-size: 2vmax;
  }
  .price_section > div > div:first-child > div:last-child {
    color: white;
    font-size: 3vmax;
  }
  .price_section > div > div:last-child > div {
    height: 44%;
  }
  .price_section > div > div:last-child > div:first-child > div:first-child {
    font-size: 1.5vmax;
  }
  .price_section > div > div:last-child > div > div:last-child {
    font-size: 2vmax;
  }
  .price_section > div > div:last-child > div:last-child > div:first-child {
    font-size: 1.5vmax;
  }
  .ctp_main {
    width: 95%;
  }
  .ctp_main h3 {
    color: white;
  }
  .ctp_main > div:nth-child(2) {
    overflow-y: scroll;
  }
  .choira_test_payment {
    width: 100%;
  }
  .choira_test_pay_status {
    height: 45%;
    margin-bottom: 3%;
    border-radius: 5px;
    position: relative;
  }

  .choira_test_pay_status > div:first-child {
    width: 8%;
  }
  .choira_test_pay_status > div:first-child > div {
    font-size: 1vmax;
    width: 20px;
    height: 20px;
    border: 2px solid #ffc701;
  }
  .choira_test_pay_status > div:nth-child(2) {
    /* border: 2px solid green; */
    gap: 10%;
    width: 65%;
    font-size: 1.5vmax;
  }
  .choira_test_pay_status > div:nth-child(3) {
    /* border: 2px solid blue; */
    width: fit-content;
    padding: 1%;
    font-size: 2vmax;
    position: absolute;
    right: 2%;
    top: 5%;
  }
  .choira_test_pay_status > div:nth-child(4) {
    width: 20%;
    margin-left: 5%;
  }
  .choira_test_pay_status p {
    color: white;
  }
  .choira_test_pay_status > div:nth-child(4) {
    font-size: 2vmax;
  }
  .payment_mode > div {
    width: 90%;
  }
  .chat-messages {
    background-color: #1e1e1e;
  }
  .message_section > div:nth-child(3) > div {
    /* border: 2px solid green; */
    width: 15%;
  }
  .message_section > div:nth-child(3) > textarea {
    background-color: transparent;
    color: white;
  }
  .message_section > div:nth-child(3) {
    position: relative;
  }
  .chat-input > img:first-child {
    position: absolute;
    left: 0;
    padding-left: 2%;
  }
  .chatPlane {
    display: none;
  }
  .chat-messages div.sent-message {
    background-color: #333333;
    color: white;
  }
  .chat-messages div.received-message {
    background-color: #333333;
    color: white;
  }

  .message_section > div:nth-child(1) > div:first-child {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  .message_section > div:nth-child(1) > div:last-child h6 {
    font-size: 1.2vmax;
  }
  .message_section > div:nth-child(1) > div:last-child {
    /* border: 2px solid red; */
    width: 70%;
  }
  .choira_edit_profile {
    z-index: 300;
    width: 90%;
    font-size: 1.8vmax;
  }
  .mobDashboard .produce-section-main2 > div:first-child {
    font-size: 3vmax;
    /* border: 2px solid red; */
    height: 100%;
    border: none;
  }
  .mobDashboard .produce-section-tabs {
    width: 100% !important;
    /* border: 2px solid green; */
    height: 100%;
  }
  .mobDashboard .produce-section-tabs > div {
    border: none;
    width: 100%;
  }
  .mobDashboard .produce-section-tabs > div > div {
    /* border: 2px solid red; */
    font-size: 3vmax;
    width: 90%;
  }
  .mobDashboard .produce-section {
    height: 12%;
    border: none;
  }
  .mobileDashboard_project_section {
    /* border: 2px solid red; */
    width: 85%;
    height: 98%;

    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 1.8vmax;
    gap: 3%;
    overflow: auto;
  }
  .mobileDashboard_project_section > div {
    width: 98%;
    min-height: 20%;
    border: 10px solid transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap: 6%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .mobileDashboard_project_section > div > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30%;
    /* border: 2px solid blue; */
    margin-top: 4%;
  }
  .mobileDashboard_project_section > div > div:first-child > div:first-child {
    /* border: 2px solid pink; */
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5%;
    font-size: 2.5vmax;
    font-weight: 600;
  }
  .mobileDashboard_project_section
    > div
    > div:first-child
    > div:first-child
    img {
    height: 100%;
  }
  .mobileDashboard_project_section > div > div:nth-child(2) {
    width: 80%;
    height: 60%;
    /* border: 2px solid red; */
    overflow: auto;
  }
  .progress-div {
    overflow-y: scroll;
  }
  .progress_main_div_content > div > div > div:nth-child(2) {
    color: rgba(224, 224, 224, 0.252);
    border-left: 5px solid rgba(224, 224, 224, 0.252);
  }
  .progress_main_div_content > div > div > div:first-child {
    color: rgba(224, 224, 224, 0.252);
  }
  .progress_main_div_content > div > div > div > div:first-child {
    color: rgba(224, 224, 224, 0.252);
  }
  .payment_mode p {
    color: white;
  }
}
