/*======================
    404 page
=======================*/
.pnfMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  width: 100vw;
  /* border: 2px solid red; */
}
.page404 {
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: "Arvo", serif;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page404 > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page404 > div:nth-child(1) {
  height: 15%;
  > h1 {
    font-size: 8vmax;
  }
}
.page404 > div:nth-child(2) {
  height: 45%;
}
.page404 > div:nth-child(3) {
  height: 35% !important;
  justify-content: flex-start;
}

.page404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("./404.gif");

  background-position: center;

  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 3vmax;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1vmax;
}
.link_404Div {
  /* border: 2px solid red; */
  width: 20%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contant_box_404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > h3 {
    font-size: 2vmax;
  }
  > p {
    font-size: 1.3vmax;
  }
}
