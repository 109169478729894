/* * {
  color: white;
} */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
select,
option {
  color: black;
}
input {
  color: black;
}

.body {
  width: 100vw;
  height: 100vh;
}
h6 {
  color: gray;
}
.SignInnavbar {
  background-color: black;
  text-align: start;
  width: 100vw;
  height: 10vh;
}
.SignInnavbar > img {
  padding: 1%;
  padding-left: 30px;
}
.wrapper {
  width: 100vw;
  height: 90vh;
  /* border: 2px solid red; */
  background-color: black;
  /* overflow: hidden; */
  overflow: hidden;
  color: white;
}
.main {
  width: 100vw;
  height: 90vh;
  /* border: 2px solid green; */
  display: flex;
}
.singer {
  /* border: 2px solid green; */
  width: 50%;
  height: 100%;
  text-align: start;
}
.deleteAccount {
  /* border: 2px solid green; */
  width: 50%;
  height: 100%;
  text-align: start;
}
.deleteAccount > img {
  height: 100%;
  width: 87%;
  /* border: 2px solid green; */
}
.singer > img {
  height: 100%;
  width: 70%;
  /* border: 2px solid green; */
}
.signup span {
  color: #ffc701;
}
.signup {
  width: 50%;
  height: 100%;
  /* border: 2px solid yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupmain {
  width: 60%;
  height: 90%;
  /* border: 2px solid red; */
  border-radius: 30px;
  overflow: hidden;
  background-color: #262727;
}
.signupmain2 {
  /* border: 2px solid red; */
  margin-left: 10%;
  margin-right: 10%;
  height: 98%;
}

.signupHeader {
  display: flex;
  justify-content: space-between;
}
.deleteHeader h1 {
  color: #ffc701;
}
.deleteHeader {
  display: flex;
  justify-content: space-between;
}
.signupHeader > div {
  /* border: 2px solid green; */
  height: 10%;
  text-align: start;
}
.signupHeader2 > h1 {
  margin-top: -25%;
  font-size: 2.8vmax;
  font-weight: 600;
}
.signupHeader2 {
  border: 2px solid #262727;
  width: 45%;
  height: 6%;
}
.sign .signupHeader span {
  font-weight: 600;
}
.signupHeader h3 {
  font-weight: 400;
  cursor: pointer;
}
.signupHeader h5 {
  color: gray;
}
.signupToggel {
  width: 100%;
  height: 10%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  > p {
    color: gray;
  }
}
.enterMob {
  /* border: 2px solid green; */
  width: 100%;
  height: 22%;
}
.enterMobInput {
  /*  */
  /* border: 2px solid orange; */
  display: flex;
  align-items: flex-start;
  /* justify-content: space-around; */
  /* gap: 1%; */
  width: 100%;
  height: 76%;
}
.enteremailinput {
  width: 100%;
  height: 40%;
  /* border: 2px solid green; */
}
.enteremailinput > input {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  padding-left: 2%;
  font-weight: 600;
}
.enterMobInnerDiv {
  height: 100%;
  width: 100%;
  /* border: 2px solid red; */
}
.mobpin2 input {
  padding-left: 38px;
}
.mobpin2 {
  position: relative;
}
.mobpin2 > div:first-child {
  /* border: 2px solid red; */
  position: absolute;
  color: gray;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  font-size: 0.7vmax;
}
.enterMobInput > div:first-child {
  /* border: 2px solid red; */
  height: 75%;
  width: 20%;
}

.enterMobInnerDiv > div:first-child {
  /* border: 2px solid red; */
  padding-bottom: 5px;
}
.enterMobInnerDiv div:nth-child(1) label {
  color: gray;
}
/* .enterMob div:nth-child(2) {
  text-align: start;
} */

.enterMobInput > div:first-child {
  height: 50%;
  /* border: 2px solid red; */
}
/* #displayinline {
  display: inline-block;
} */
.enterMobInput select {
  border: 2px solid red;
  border-radius: 10px;
  width: 85%;
  height: 95%;
  border: none;
  text-align-last: center;
  outline: none;
  text-transform: uppercase;
}
.enterMobInput > div:nth-child(2) {
  /* border: 2px solid green; */
  height: 50%;
  width: 59%;
}
.enterMobInput input {
  /* border: 2px solid red; */
  border-radius: 10px;
  width: 115%;
  height: 95%;
  outline: none;
  border: none;
}
.verify > h6 {
  color: gray;
  text-align: start;
}
.verify {
  /* border: 2px solid green; */
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  margin-top: -12%;
}
.hrLine {
  display: flex;
  align-items: center;
  padding-top: 20%;
}
.hrLine small {
  padding-left: 2%;
  padding-right: 2%;
  color: gray;
}
.hrLine div {
  border: 1px solid white;
  width: 50%;
  height: 0%;
}
.footer {
  /* border: 2px solid yellow; */
  width: 100%;
  height: 45vh;
}
.signinOption > div {
  cursor: pointer;
}
.signinOption {
  /* border: 2px solid blue; */
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.continue button {
  cursor: pointer;
}

.signinOption small {
  color: black;
  padding-bottom: 3px;
}

.signinOption > div:first-child {
  width: 68%;
  background-color: #fff9e3;
}
.signinOption div {
  /* border: 2px solid pink; */
  width: 12%;
  height: 60%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10%;
  background-color: #ffffff;
}
.signinOption img {
  height: 20px;
  width: 20px;
}
.continue {
  /* border: 2px solid green; */
  height: 30%;
  margin-top: 10%;
  width: 100%;
}
.continue > div {
  width: 100%;
  height: 48%;
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
}
.continue button {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
  border: none;
  /* background-color: #ffc701; */
  color: #262727;
  font-weight: 600;
}
.continue h6 {
  text-align: center;
}
.continue > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.enterMobinnerdiv2 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.verify2 {
  /* border: 2px solid red; */
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-align: start; */
}
.enterMobinnerdiv2 div:nth-child(2) {
  /* border: 2px solid blue; */
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.verify2Input {
  display: flex;
  gap: 5%;

  width: 60%;
  height: 35%;
  /* border: 2px solid red; */
}
.verify2Input input {
  width: 15%;
  outline: none;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-weight: 600;
  font-size: 1.3vmax;
}

.timer {
  /* border: 2px solid red; */
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.timer span {
  cursor: pointer;
}
.timer b {
  color: white;
}
.visiblity {
  display: none;
}
.visiblity2 {
  visibility: hidden;
}

.verifyContinue2 {
  margin-top: 55%;
}
.enter-mob-inner-div3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40vh;
  /* border: 2px solid red; */
}
/* .enter-mob-inner-div3 > div {
  border: 2px solid yellow;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80%;
} */
/* .enter-mob-inner-div3 > div > div {
  border: 2px solid green;
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
} */
.enter-mob-inner-div3 input {
  /* border: 2px solid red; */
  height: 100%;
}
/* .input-title {
  border: 2px solid green;
} */
.input-title > div:first-child {
  /* border: 2px solid green; */
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.input-title > div:nth-child(2) {
  /* border: 2px solid yellow; */
  width: 100%;
  height: 70%;
}
.input-title input {
  width: 97%;
  height: 70%;
  border-radius: 10px;
  border: none;
  outline: none;
  padding-left: 10px;
}
.input-title h5 {
  color: gray;
  padding-bottom: 10px;
  /* border: 2px solid blue; */
}

.wrong-details {
  border: 1px solid red !important;
  border-color: red;
  color: red !important;
  outline: red;
}

.date-input input::-webkit-input-placeholder {
  color: gray;
}
.date-input input::-moz-placeholder {
  color: gray;
}

.date-input input:-ms-input-placeholder {
  color: gray;
}

.date-input input::placeholder {
  color: gray;
}
.date-input input {
  text-transform: uppercase;
}
.deleteOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #09090981;
  top: -100%;
  transition: 1s ease;
}
.deleteOverlayAfter {
  top: 0%;
  transition: 1s ease;
}
.deletePopup {
  left: 50%;
  top: -100%;
  position: fixed;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 80%;
  /* border: 2px solid red; */
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  background-color: #262727;
  transition: 1s ease;
}
.deletePopupAfter {
  top: 50%;

  transition: 1s ease;
}
.cancelPopup {
  width: 100%;
  height: 10%;
  /* border: 2px solid yellow; */
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-top: 5%;
  padding-right: 3%;
  font-size: 2vmax;
}
.cancelPopup:hover {
  color: #ffaf01;
}
.popupTitle {
  width: 100%;
  height: 15%;
  /* border: 2px solid green; */
  font-size: 2vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffc701;
}
.popupDecription {
  width: 100%;
  height: 20%;
  /* border: 2px solid blue; */
  text-align: center;
  color: white;
}
.popupImg {
  width: 100%;
  height: 35%;
  /* border: 2px solid #fff9e3; */
  display: flex;
  justify-content: center;
}
.popupImg > img {
  height: 100%;
  width: 60%;
}
.popupbtn {
  width: 100%;
  height: 20%;
  /* border: 2px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupbtn button:hover {
  background-color: #ffaf01;
}
.popupbtn button {
  width: 80%;
  height: 45%;
  border: none;
  outline: none;
  background-color: #ffc701;
  color: black;
  font-size: 1.2vmax;
  border-radius: 10px;
  font-weight: 600;
}
@media only screen and (max-width: 600px) {
  .singer {
    display: none;
  }
  .signup {
    width: 100%;
    /* border: 2px solid red; */
  }
  .signupmain {
    width: 80%;
    height: 70%;
  }
}
