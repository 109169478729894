::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee;
  border-radius: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
}
.studioMainScreen {
  width: 88vw;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5%;
  /* overflow: hidden; */
}
.studioHeader {
  width: 100%;
  min-height: 8%;
  max-height: 8%;
  /* border: 2px solid green; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  background-color: white;
}
.studioHeader > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vmax;
}
.studioHeader > div:nth-child(1) {
  width: 80%;
  height: 60%;
  border: 2px solid #dddddd;
  border-radius: 20px;
}
.studioHeader > div:nth-child(1) > input {
  height: 100%;
  width: 100%;
  padding-right: 5%;
  border: none;
  outline: none;
  border-radius: 20px;
  font-size: 0.8vmax;
  padding-left: 2%;
}
.studioHeader > div:nth-child(1) input::placeholder {
  text-align: center;
  font-size: 0.8vmax;
  color: black;
  font-weight: 540;
}
.studioHeader > div:nth-child(2) {
  width: 2%;
  height: 80%;
  /* border: 2px solid green; */
  margin-left: -5%;
}
.studioHeader > div:nth-child(3) {
  width: 3%;
  height: 80%;
  border: 6px solid transparent;
  flex-direction: column;
  position: relative;
}
.studioHeader > div:nth-child(4) {
  width: 5%;
  height: 80%;
  /* border: 2px solid blue; */
}
.notifyIcon {
  position: absolute;
  top: 0;
  right: 0;
  color: orange;
}
.onboardStudio {
  width: 80%;
  height: 40%;
  /* border: 2px solid red; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.onboardStudio > div {
  display: flex;
  align-items: center;
}

.onboardStudio > div:nth-child(1) {
  width: 40%;
  height: 15%;
  /* border: 2px solid green; */
  font-size: 1vmax;
  color: #ffc701;
  font-weight: 600;
}
.onboardStudio > div:nth-child(2) {
  width: 40%;
  height: 15%;
  /* border: 2px solid green; */
  font-size: 1vmax;
  align-items: flex-start;

  font-weight: 600;
}
.onboardStudio > div:nth-child(3) {
  width: 60%;
  height: 60%;
  /* border: 2px solid green; */
  font-size: 1vmax;

  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3%;
  margin-top: 2%;
}
.onboardStudio > div:nth-child(3) > div {
  width: 20%;
  height: 80%;
  /* border: 2px solid red; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.onboardStudio > div:nth-child(3) > div > div:first-child {
  width: 100%;
  height: 80%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vmax;
}
.onboardStudio > div:nth-child(3) > div > div:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8vmax;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  /* border: 2px solid red; */

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: #ffc701;
      cursor: pointer;
    }

    &.selected {
      background-color: #ffc701;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.mainPaginationDiv {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
.paginationgoto {
  width: 60%;
  height: 100%;
  /* border: 2px solid pink; */
}
.paginationgoto > input {
  height: 4vh;
  padding-left: 3%;
  width: 40%;
  border: none;
  outline: none;
  margin-right: 5%;
  border-radius: 5px;
  /* background-color: #f0f0f0; */
}
.paginationgoto > button {
  width: 30%;
  border-radius: 5px;
  font-size: 0.8vmax;
  background-color: white;
}
.paginationgoto > button:hover {
  background-color: #ffc701;
}
.allStudioDetailsPage {
  width: 100%;
  height: 90%;
  /* border: 2px solid red; */
  margin-top: -2%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.allStudiobtndiv {
  width: 95%;
  height: 10%;
  border: 2px solid green;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.allStudiobtndiv > div:nth-child(2) {
  width: 28%;
  height: 100%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.allStudiobtndiv > div:nth-child(1) {
  font-size: 1.3vmax;
  font-weight: 600;
}

.bookingStudiobtn {
  width: 95%;
  height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 1sec ease;
}

.bookingStudiobtn > div:nth-child(2) {
  width: 48%;
  height: 100%;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}
.bookingStudiobtn > div:nth-child(1) {
  font-size: 1vmax;
  font-weight: 600;
  border: 2px solid transparent;
  width: 35%;
  height: 80%;
}
.bookingStudiobtn > div:nth-child(1) > div {
  width: 90.5%;
  height: 100%;
  /* border: 2px solid pink; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  background-color: white;
}
.bookingStudiobtn > div:nth-child(1) > div > div:hover {
  background-color: #ffc701;
  cursor: pointer;
  color: black;
}
.bookingStudiobtn > div:nth-child(1) > div > div {
  height: 100%;
  width: fit-content;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookingStudiobtn > div:nth-child(1) > div > div:nth-child(4) {
  border: 2px solid transparent;
  padding-right: 6%;
  padding-left: 5%;
}
.studioImage {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
}
.studioImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 10px;
}
.studioTabelDiv {
  width: 95%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* border: 2px solid yellow; */
  background-color: white;
  border-radius: 10px;
}
.studioTabelDiv small {
  color: rgba(173, 177, 181, 1);
}
.studioTabelDiv > div {
  width: 95%;
  height: 85%;
  /* border: 2px solid blue; */

  overflow-y: scroll;
}
.studioTabelDiv > div table {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.studiotabelHead {
  width: 100%;
  height: 50px;
  background-color: rgba(243, 246, 249, 1);
  text-align: center;
}
.studiotabelHead > tr {
  width: 80%;
  /* border: 2px solid green; */
}
.studiotabelHead > tr > th:nth-child(1) {
  width: 25%;
}
.studiotabelHead > tr > th:nth-child(2) {
  width: 15%;
}
.studiotabelHead > tr > th:nth-child(3) {
  width: 25%;
}
.studiotabelHead > tr > th:nth-child(4) {
  width: 15%;
}

td {
  text-align: center;
}
.tableActionbtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.tableActionbtn > div:nth-child(2) {
  width: 35%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2vmax;
  height: 40%;
}
.tabelpaginationDiv {
  width: 95%;
  /* border: 2px solid red; */
  height: 6%;
  display: flex;
  align-items: flex-end;
  margin-top: 1%;
}
.tableActionbtn > div:nth-child(1) {
  width: 60%;
  /* border: 2px solid pink; */
}
.tableActionbtn select {
  /* background-color: red; */
  color: black;
  border: none;
  outline: none;
  width: 100%;
  width: 100%;
  height: 100%;
  padding: 5%;
}
.tableActionbtn option {
  background-color: white;
  color: black;
}
.studioFooter {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid green; */
  background-color: white;
  padding-left: 2.5%;
  padding-right: 2.5%;
  margin-top: 1%;
  margin-bottom: -0.3%;
}

.addNewStudioTitle {
  width: 70%;
  height: 10%;
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vmax;
  font-weight: 600;
}
.addNewStudioPage {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
}
.addNewStudioPage > div {
  width: 100%;
  height: 100%;
  /* border: 2px solid blue; */
  border-radius: 10px;
  background-color: white;
}
.addNewStudioPage > div:first-child {
  display: flex;
  justify-content: space-around;
}
.addNewStudioPage > div > div {
  width: 45%;
  height: 100%;
  /* border: 2px solid pink; */
  padding-top: 2%;
  display: flex;
  gap: 2%;
  flex-direction: column;
}
.addNewStudioinputBox {
  width: 100%;
  height: 11%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.addNewStudioinputBox input,
.addNewStudioinputBox select {
  border-radius: 10px;

  width: 100%;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 5%;
  color: black;
}
.addNewStudioinputBox > label {
  font-size: 1vmax;
  font-weight: 600;
}
.addNewStudioinputBox select {
  padding: 1.8%;
  margin-top: 0%;
  height: 80%;
}
.amenitesCheckbox {
  border: 2px solid #dddddd;
  width: 100%;
  height: 30%;
  border-radius: 10px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  gap: 5%;
  overflow-y: scroll;
}
.amenitesCheckbox > div {
  /* border: 2px solid red; */
  padding-left: 1%;
  font-size: 1vmax;

  /* align-items: center;
  justify-content: center; */
}
.amenitesCheckbox input[type="checkbox"] {
  accent-color: #ffc701;
}
.addNewStudioimgBox {
  /* border: 2px solid red; */
  width: 100%;
  height: 30%;
}
.addNewStudioimgBox label {
  font-size: 1vmax;
  font-weight: 600;
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
}
.addNewStudioimgBox > div {
  margin-top: 2%;
  width: 100%;
  border: 2px dashed black;
  height: 85%;
  border-radius: 10px;
}
.addNewStudioimgBox > div > label {
  width: 100%;
  height: 100%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.addNewStudioimgBox > div > input {
  display: none;
}
.addNewStudioimgBox > div > label span {
  color: #ffc701;
}
.addNewStudioimgBox > div > label > div:first-child > img {
  width: 40px;
  height: 30px;
}
.showMultipleStudioImage {
  width: 100%;
  height: 100%;
  padding-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid red;
}
.showMultipleStudioImage > div:nth-child(1) {
  width: 95%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  border: 2px solid green;
}
.showMultipleStudioImage > div:nth-child(2) {
  align-self: flex-start;
  padding-left: 2%;
}

.showMultipleStudioImage > div:nth-child(1) > div {
  /* Add any styling for each grid item */
  border: 1px solid #ccc;
  width: 18%;
  height: 80%;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
  text-align: center;
}
.showlocationDiv {
  width: 100%;
  height: 15%;
  /* border: 2px solid red; */
}
.addNewStudioPage > div:nth-child(2) {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* border: 2px solid red; */
  min-height: 100%;
  height: fit-content;
}
.addNewStudioPage > div:nth-child(2) > div {
  width: 95%;
  height: 90%;
  /* border: 2px solid green; */
}
.addNewStudioinputBox2 {
  width: 100%;
  min-height: 15vh;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.addNewStudioinputBox2 textarea {
  border-radius: 10px;
  min-height: 12vh;
  max-width: 100%;
  min-width: 50%;
  max-height: 50vh;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 1%;
  color: black;
  font-size: 0.8vmax;
  font-weight: 600;
  padding-top: 1%;
}
.addNewStudioinputBox2 > label {
  font-size: 1vmax;
  font-weight: 600;
}
.roomAndClassSection {
  width: 100%;
  height: 34%;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.roomAndClassSection > div {
  width: 48%;
  /* border: 2px solid red; */
  height: 100%;
}
.addNewStudioinputBox3 {
  width: 100%;
  height: 90%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 10%;
}
.addNewStudioinputBox3 input {
  border-radius: 10px;

  width: 100%;
  height: fit-content;
  outline: none;
  border: 2px solid #dddddd;
  padding-left: 5%;
  color: black;
}
.addNewStudioinputBox3 > label {
  font-size: 1vmax;
  font-weight: 600;
}
.addTeamDetailDiv {
  width: 100%;
  min-height: 100%;
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 5%;
}
.addTeamDetailDynamicDiv {
  /* overflow-y: scroll; */
  display: flex;
  align-items: center;

  flex-direction: column;
  gap: 5%;
  border-radius: 10px;

  width: 100%;
  min-height: 90%;

  /* height: fit-content; */
  outline: none;

  color: black;
  /* overflow: scroll; */

  padding-top: 5%;
  /* border: 2px solid pink; */
}
.addTeamDetailDiv > label {
  font-size: 1vmax;
  font-weight: 600;
}

.addTeamDetailMainDiv {
  width: 95%;
  min-height: 10vh;

  border: 2px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: relative;
  padding-left: 2%;
  margin-bottom: 5%;
  /* flex-direction: column; */
}
.addTeamDetailMainDiv > div:nth-child(1) {
  width: 20%;
  height: 7vh;
  border: 2px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vmax;
  color: #ffc701;
}
.addTeamDetailMainDiv > div:nth-child(1) > div {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* overflow: hidden; */
  position: relative;
}
.addTeamDetailMainDiv > div:nth-child(1) > div img {
  width: 100%;
  height: 100%;
}
.addTeamDetailMainDiv > div:nth-child(1) > div > span {
  position: absolute;
  top: -20%;
  right: -20%;
}
.addTeamDetailMainDiv > div:nth-child(2) {
  /* border: 2px solid blue; */
  margin-left: 10%;
}
.addTeamDetailMainDiv > div:nth-child(2) input:nth-child(1) {
  font-size: 1vmax;
  font-weight: 600;
}
.addTeamDetailMainDiv > div:nth-child(2) input {
  width: 95%;
  border: none;
  outline: none;
}
.addTeamDetailbtn {
  /* position: absolute; */
  align-self: start;
  bottom: 0%;

  left: 2%;
  font-size: 1.2vmax;
  color: black;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-bottom: 2%;
  cursor: pointer;
}

.addNewRoomPage {
  width: 70%;
  height: 95%;
  /* border: 2px solid red; */
  overflow-y: scroll;
}
.addNewRoomPage > div {
  width: 100%;
  height: 140%;
  /* border: 2px solid blue; */
  border-radius: 10px;
  background-color: white;
  padding-bottom: 5%;
}
.addNewRoomPage > div:first-child {
  display: flex;
  justify-content: space-around;
}
.addNewRoomPage > div > div {
  width: 45%;
  height: 100%;
  /* border: 2px solid pink; */
  padding-top: 2%;
  display: flex;
  gap: 2%;
  flex-direction: column;
}

.addNewRoomPage > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addNewRoomPage > div:nth-child(2) > div {
  width: 95%;
  height: 90%;
  /* border: 2px solid green; */
}
.defaultLabel {
  font-size: 1vmax;
  font-weight: 600;
}
.cancelTeamDetailUpload {
  position: absolute;
  /* float: left; */
  top: -10%;
  right: -2%;
  font-size: 1.1vmax;

  color: #ffc701;
  cursor: pointer;
  /* mix-blend-mode: color-burn black; */
}
.cancelImageUpload {
  position: absolute;
  /* float: left; */
  top: -12%;
  right: 0%;
  font-size: 1vmax;

  color: #ffc701;
  cursor: pointer;
  /* mix-blend-mode: color-burn black; */
}
.addNewStudioPage .addNewStudioinputBox {
  height: 20%;
}
@media only screen and (min-width: 1024px) and (max-width: 1903px) {
  .addNewStudioPage .addNewStudioinputBox {
    height: 10%;
    background-color: red;
  }
}
