.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img {
    width: 60px;
    height: 60px;
  }
}
