.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 78%;

  position: fixed;
  /* border: 2px solid red; */
}

.child {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderImage {
  width: 50px !important;
  height: 50px !important;
}

.noDataImage {
  width: 90%;
  height: 90%;
}
